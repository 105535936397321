import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import ApartmentSearch from "../components/apartmentSearch"
import InfoImageFullWidth from "../components/infoImageFullWidth"
import InfoInNumbers from "../components/infoInNumbers"
import InfoWithList from "../components/infoWithList"
import InfoContact from "../components/infoContact"

const IndexPage = () => {
	const AcfQuery = useStaticQuery(
		graphql`
		  query HomeQuery {
			allWordpressPage(filter: {title: {eq: "Strona główna"}}) {
			  edges {
				node {
				  acf {
					banner_uptitle
					banner_title
					banner_text
					inwestment_title
					inwestment_text_one
					inwestment_text_two
					info_list_title
					info_list_text
					contact_info_title
					contact_info_text
					banner_background {
						localFile {
							url
						}
					}
					inwestment_image {
						localFile {
							url
						}
					}
					info_numbers {
					  info_numbers_number
					  info_numbers_text
					}
					info_list_list {
					  list_item
					}
					contact_info_image {
						localFile {
							url
						}
					}
					contact_info_link {
					  target
					  title
					  url
					}
				  }
				}
			  }
			}
		  }
		`
	)

	const data = AcfQuery.allWordpressPage.edges[0].node.acf;

	return (
		<Layout>
			<SEO title="Strona główna" />
			<Banner 
				uptitle={ data.banner_uptitle }
				title={ data.banner_title }
				text={ data.banner_text }
				image={ data.banner_background.localFile.url }
				// image={ require('../assets/img/bannerImage.jpg') }
			/>
			<ApartmentSearch />
			<InfoImageFullWidth 
				title={ data.inwestment_title }
				textOne={ data.inwestment_text_one }
				textTwo={ data.inwestment_text_two }
				image={ data.inwestment_image.localFile.url }
				// image={ require('../assets/img/InfoFullWidth.jpg') }
			/>
			<InfoInNumbers 
				infoNumbers={ data.info_numbers }
			/>
			<InfoWithList 
				title={ data.info_list_title }
				text={ data.info_list_text }
				list={ data.info_list_list }
			/>
			<InfoContact 
				image={ data.contact_info_image.localFile.url }
				// image={ require('../assets/img/InvestmentInfo2.jpg') }
				title={ data.contact_info_title }
				text={ data.contact_info_text }
				button={ data.contact_info_link }
			/>
		</Layout>
	)
}

export default IndexPage
