import React from "react"

const Banner = ({ uptitle, title, text, image }) => (
	<section className="banner">
		<div className="container">
			<div className="row">
				<div className="col-12">
                    <div className="banner__content">
                        <div className="content__background">
                            <h1 className="content__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                            <p className="content__subtitle subtitle">
                                { text }
                            </p>
                        </div>
                        <div className="content__info">
                            <div className="info__box">
                                <img src={require('../assets/img/infoIcon.png')} alt="informacja" />
                            </div>
                            <p className="info__text" dangerouslySetInnerHTML={{ __html: uptitle }}></p>
                        </div>
                    </div>
				</div>
			</div>
		</div>
        <div className="banner__background" 
            style={{ backgroundImage: `url(${ image })`, }}
        ></div>
	</section>
)

export default Banner