import React from "react"

const InfoWithList = ({ title, text, list }) => (
    <section className="info-with-list">
        <div className="container">
            <div className="row info-with-list__holder">
                <div className="col-12 col-lg-6">
                    <div className="info-with-list__list">
                        { list.map((item, index) => (
                            <div className="list__item" key={ index }>
                                <img src={ require('../assets/img/listMark.png') } alt="" className="item__image" />
                                <h3 className="item__title" dangerouslySetInnerHTML={{ __html: item.list_item }}></h3>
                            </div>
                        )) }
                    </div>
                </div>
                <div className="col-12 col-lg-6 info-with-list__content">
                    <h2 className="title-underline mb-40" dangerouslySetInnerHTML={{ __html: title }}></h2>
                    <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
            </div>
        </div>
    </section>
)

export default InfoWithList
