import React from "react"

const InfoImageFullWidth = ({ title, textOne, textTwo, image }) => (
    <section className="info-image-full-width">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="info-image-full-width__content">
                        <h2 className="title-underline mb-40" dangerouslySetInnerHTML={{ __html: title }}></h2>
                        <p dangerouslySetInnerHTML={{ __html: textOne }}></p>
                        <p dangerouslySetInnerHTML={{ __html: textTwo }}></p>
                    </div>
                </div>
            </div>
        </div>
        <div className="info-image-full-width__image" 
            style={{ backgroundImage: `url(${ image })`, }}
        ></div>
    </section>
)

export default InfoImageFullWidth
