import React from "react"
import { Link } from "gatsby"

const InfoContact = ({ image, title, text, button }) => (
    <section className="info-contact">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="info-contact__content">
                        <h2 className="title-underline mb-40" dangerouslySetInnerHTML={{ __html: title }}></h2>
                        <p className="mb-40" dangerouslySetInnerHTML={{ __html: text }}></p>
                        <Link
                            to={ button.url }
                            className="btn"
                        >
                            { button.title }
                        </Link>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <img src={ image } alt="" className="info-contact__image" />
                </div>
            </div>
        </div>
    </section>
)

export default InfoContact
