import React from "react"

const InfoInNumbers = ({ infoNumbers }) => (
    <section className="info-in-number background--gray">
        <div className="container">
            <div className="row">
                <div className="col-12 info-in-number__holder">
                    { infoNumbers.map((item, index) => (
                        <div className="info-in-number__item" key={ index }>
                            <h3 className="item__title" dangerouslySetInnerHTML={{ __html: item.info_numbers_text }}></h3>
                            <p className="item__number" dangerouslySetInnerHTML={{ __html: item.info_numbers_number }}></p>
                        </div>
                    )) }
                </div>
            </div>
        </div>
    </section>
)

export default InfoInNumbers
